import axios from "axios";
import moment from "moment";

const environment = process.env.NODE_ENV;
let KEY = process.env.REACT_APP_DEVELOPMENT_API_KEY;
let baseURL = process.env.REACT_APP_API_DEVELOPMENT_URL;

if (environment === "production") {
  KEY = process.env.REACT_APP_PRODUCTION_API_KEY;
  baseURL = process.env.REACT_APP_API_PRODUCTION_URL;
}
if (environment === "test") {
  KEY = process.env.REACT_APP_TEST_API_KEY;
  baseURL = process.env.REACT_APP_API_TEST_URL;
}

const contentType = "application/json";

export const getContractDetails = async (contractNumber) => {
  return await axios(baseURL + "standard/OPgetcontract/details", {
    method: "POST",
    headers: {
      "Content-Type": contentType,
      Authorization: KEY,
    },
    data: {
      onPointContractNumber: contractNumber,
    },
  });
};

export const getFailureTypes = async (tierCode) => {
  return await axios(baseURL + "standard/variables/getfailuretypes", {
    method: "POST",
    headers: {
      Authorization: KEY,
      "Content-Type": contentType,
    },
    data: {
      tierID: tierCode,
    },
  });
};

export const getServiceOrderList = async (userEmail) => {
  return await axios(baseURL + "standard/lookups/getserviceorders", {
    method: "POST",
    headers: {
      Authorization: KEY,
      "Content-Type": contentType,
    },
    data: {
      serviceAvengerContractNumber: "",
      serviceOrderNumber: "",
      customerEmail: "",
      b2bEmail: userEmail,
    },
  });
};

export const getServiceOrder = async (serviceOrderNumber) => {
  return await axios(baseURL + "standard/lookups/getserviceorders", {
    method: "POST",
    headers: {
      Authorization: KEY,
      "Content-Type": contentType,
    },
    data: {
      serviceAvengerContractNumber: "",
      serviceOrderNumber,
      customerEmail: "",
      b2bEmail: ""
    },
  });
};

export const createContract = async (
  address1,
  address2,
  brand,
  cellPhone,
  city,
  datetime,
  email,
  firstName,
  lastName,
  modelNumber,
  otherPhone,
  serialNumber,
  stateProvince,
  zipCode
) => {
  const today = new Date(datetime);
  const thirtyDaysAgo = new Date(new Date().setDate(datetime.getDate() - 30));
  const userEmailAddressShort =
    sessionStorage.getItem("email").length > 30
      ? sessionStorage.getItem("email").substring(0, 30)
      : sessionStorage.getItem("email");
  return await axios(
    baseURL + "standard/dealer/createcontract/initialcontract",
    {
      method: "POST",
      headers: {
        "Content-Type": contentType,
        Authorization: KEY,
      },
      data: {
        contract: {
          contractPurchaseDate: moment(thirtyDaysAgo).format("YYYY-MM-DD"),
          contractOtherTax: 0,
          contractRetailPrice: 200.0,
          contractSalesTax: 0,
          contractSKU: "STAYADMIN1x200x",
          contractSourceName: "STAYMREP",
          dealerID: "US32819SMST",
          dealerInvoiceNumber: serialNumber + today.toISOString(),
        },
        customer: {
          address1,
          address2,
          businessName: "",
          cellPhone: cellPhone.replace(/\D/g, ""),
          city,
          countryCode: "USA",
          dealerCustomerID: userEmailAddressShort,
          email,
          firstName,
          languageCode: "en-us",
          lastName,
          otherPhone: otherPhone.replace(/\D/g, ""),
          stateProvince,
          zipCode,
        },
        general1: "",
        general2: "",
        general3: "",
        general4: "",
        product: {
          catalogItemNumber: "1",
          manufacturerName: brand === "Other" ? "UNK" : brand,
          modelName: "ChromeBook",
          modelNumber,
          oemLaborWarrantyTerm: "0",
          oemMajorCompTerm: "0",
          oemOtherTerm: "0",
          oemPartsWarrantyTerm: "0",
          productCondition: "N",
          productDeliveryInstallDate: moment(today).format("YYYY-MM-DD"),
          productPurchaseDate: moment(today).format("YYYY-MM-DD"),
          productPurchasePrice: 500.0,
          serialNumber,
          serviceLocation: "Depot",
          subCategory: "ChromeBook",
        },
      },
    }
  );
};

export const createFNOL = async (
  address1,
  address2,
  city,
  claimFailureNote,
  claimFunctionalityStatus,
  claimStorySymptom,
  contractNumber,
  datetime,
  email,
  firstName,
  lastName,
  mobileNumber,
  serialNumber,
  servicerNotes,
  state,
  zipCode
) => {
  const today = new Date(datetime);
  let servicerNotesText = servicerNotes?.replace(/staymobile /gi, "");

  return await axios(baseURL + "standard/claims/fnolrequest", {
    method: "POST",
    headers: {
      Authorization: KEY,
      "Content-Type": contentType,
    },
    data: {
      headerInfo: {
        serviceAvengerContractNumber: contractNumber,
        externalContractNumber: serialNumber + today.toISOString(),
        productSequenceNumber: "1",
      },
      customerInfo: {
        firstName,
        lastName,
        businessName: "",
        email,
        address1,
        address2,
        city,
        state,
        zipCode,
        country: "USA",
        mobileNumber: mobileNumber.replace(/\D/g, ""),
      },
      claimInfo: {
        serviceType: "Service",
        callerType: "Customer",
        externalClaimNumber: "UNKNOWN",
        claimRequestPriority: "Normal",
        claimDateConsumerFiled: moment(today).format("YYYY-MM-DD"),
        claimDateRepaired: "",
        claimDateAdjudicated: "",
        claimStatusApproveReject: "",
        claimAmount: 0.0,
        claimReimbursementAmount: 0.0,
        claimReimbursementMethod: "First Notice",
        claimStorySymptom,
        claimFunctionalityStatus,
        claimFailureType: "ST",
        claimFailureNote,
        claimPaymentMethodAccount: sessionStorage.getItem("email"),
      },
      appointmentInfo: {
        preferenceDate1: null,
        preferenceTimeSlot1: null,
        preferenceDate2: null,
        preferenceTimeSlot2: null,
        preferenceDate3: null,
        preferenceTimeSlot3: null,
        appointmentNotes:
          servicerNotesText.length === 0
            ? "StayMobile"
            : "StayMobile - " + servicerNotes,
        preferredServicerName: "",
        preferredServicerAddress1: "",
        preferredServicerAddress2: "",
        preferredServicerCity: "",
        preferredServicerState: "",
        preferredServicerZipcode: "",
        preferredServicerPhone: "",
      },
    },
  });
};

export const getBookedAppointment = async (
  contractNumber,
  serviceOrderNumber
) => {
  return await axios(baseURL + "standard/lookups/getbookedappointments", {
    method: "POST",
    headers: {
      "Content-Type": contentType,
      Authorization: KEY,
    },
    data: {
      serviceAvengerContractNumber: contractNumber,
      serviceOrderNumber: serviceOrderNumber,
    },
  });
};
